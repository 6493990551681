import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit'
import dashboardApi from '~/api/dashboardApi'
import serviceRequest from '~/app/serviceRequest'
import {DashBoardTableType} from './dashboard-filter/DashboardFilter.type'

export const getOrderTotalPaidUnpaid = createAsyncThunk(
  'dashboard/getOrderTotalPaidUnpaid',
  async (params: {year: boolean; date: string}, thunkAPI) => {
    const {year, date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getOrderTotalPaidUnpaid,
      payload: {year, date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getOrderTable = createAsyncThunk(
  'dashboard/getOrderTable',
  async (params: {paid: boolean; year: boolean; date: string}, thunkAPI) => {
    const {paid, year, date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getOrderTable,
      payload: {paid, year, date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getOrderChart = createAsyncThunk(
  'dashboard/getOrderChart',
  async (params: {year: boolean; date: string}, thunkAPI) => {
    const {year, date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getOrderChart,
      payload: {year, date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getOrderLineChart = createAsyncThunk(
  'dashboard/getOrderLineChart',
  async (params: {year: boolean; start: string; ennd: string}, thunkAPI) => {
    const {year, start, ennd} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getOrderLineChart,
      payload: {year, start, ennd},
      options: {
        skipLoader: false,
      },
    })
  },
)

export const getAuditTotalPaidUnpaid = createAsyncThunk(
  'dashboard/getAuditTotalPaidUnpaid',
  async (params: {year: boolean; date: string}, thunkAPI) => {
    const {year, date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getAuditTotalPaidUnpaid,
      payload: {year, date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getAuditTable = createAsyncThunk(
  'dashboard/getAuditTable',
  async (params: {paid: boolean; year: boolean; date: string}, thunkAPI) => {
    const {paid, year, date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getAuditTable,
      payload: {paid, year, date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getAuditChart = createAsyncThunk(
  'dashboard/getAuditChart',
  async (params: {year: boolean; date: string}, thunkAPI) => {
    const {year, date} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getAuditChart,
      payload: {year, date},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getAuditLineChart = createAsyncThunk(
  'dashboard/getAuditLineChart',
  async (params: {year: boolean; start: string; ennd: string}, thunkAPI) => {
    const {year, start, ennd} = params
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getAuditLineChart,
      payload: {year, start, ennd},
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getTodayOverview = createAsyncThunk(
  'dashboard/getTodayOverview',
  async (data: unknown | null, thunkAPI) => {
    return serviceRequest({
      dispatch: thunkAPI.dispatch,
      serviceMethod: dashboardApi.getTodayOverview,
      payload: data,
      options: {
        skipLoader: false,
      },
    })
  },
)
export const getDayOverview = createAsyncThunk('dashboard/getDayOverview', async (params: {date: string}, thunkAPI) => {
  const {date} = params
  return serviceRequest({
    dispatch: thunkAPI.dispatch,
    serviceMethod: dashboardApi.getDayOverview,
    payload: {date},
    options: {
      skipLoader: false,
    },
  })
})

// export const createFactory = createAsyncThunk('factory/createFactory', async (data: any | undefined, thunkAPI) => {
//   return serviceRequest({
//     dispatch: thunkAPI.dispatch,
//     serviceMethod: factoryApi.postFactory,
//     payload: data,
//     options: {
//       skipLoader: false,
//     },
//   })
// })

// export const updateFactory = createAsyncThunk(
//   'factory/updateFactory',
//   async (data: PayloadFactory | undefined, thunkAPI) => {
//     return serviceRequest({
//       dispatch: thunkAPI.dispatch,
//       serviceMethod: factoryApi.updateFactory,
//       payload: data,
//       options: {
//         skipLoader: false,
//       },
//     })
//   },
// )

// export const deleteFactory = createAsyncThunk('factory/deleteFactory', async (data: string | undefined, thunkAPI) => {
//   return serviceRequest({
//     dispatch: thunkAPI.dispatch,
//     serviceMethod: factoryApi.deleteFactory,
//     payload: data,
//     options: {
//       skipLoader: false,
//     },
//   })
// })

export type State = {
  loading: string
  data: DashBoardTableType[]
  error: string
  paginationData: {page: number; pageSize: number; totalPage: number}[]
}

const initialState: State = {
  loading: 'idle',
  data: [] as DashBoardTableType[],
  error: '',
  paginationData: Array(6).fill({page: 1, pageSize: 10, totalPage: 1}),
}

const dashboard = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    handleChangePage: (state, action) => {
      const {activePage, page} = action.payload
      state.paginationData[activePage].page = page
    },
    handleChangePageSize: (state, action) => {
      const {activePage, pageSize} = action.payload
      state.paginationData[activePage].pageSize = pageSize
    },
    handleChangeTotalPage: (state, action) => {
      const {activePage, totalPage = 1} = action.payload
      state.paginationData[activePage].totalPage = totalPage
    },
  },
  extraReducers: () => {},
  //   extraReducers: builder => {
  //     builder
  //       //getFactories
  //       .addCase(getFactories.pending, state => {
  //         state.loading = 'loading'
  //         state.error = initialState.error
  //       })
  //       .addCase(getFactories.fulfilled, (state, action) => {
  //         state.loading = 'succeeded'
  //         const payload = action?.payload
  //         if (payload?.isSuccess) {
  //           state.data = payload?.data?.items
  //         } else if (!payload?.IsSuccess) {
  //           state.error = payload?.Data?.ErrorMessage
  //         }
  //       })
  //       .addCase(getFactories.rejected, (state, action) => {
  //         state.loading = 'failed'
  //         state.error = action.error.message as string
  //       })

  //       //createFactory
  //       .addCase(createFactory.pending, state => {
  //         state.loading = 'loading'
  //         state.error = initialState.error
  //       })
  //       .addCase(createFactory.fulfilled, (state, action) => {
  //         state.loading = 'succeeded'
  //         const payload = action.payload
  //         if (payload?.isSuccess) {
  //           state.data.unshift(payload.data)
  //         } else if (!payload?.IsSuccess) {
  //           state.error = payload?.Data?.ErrorMessage
  //         }
  //       })
  //       .addCase(createFactory.rejected, (state, action) => {
  //         state.loading = 'failed'
  //         state.error = action.error.message as string
  //       })

  //       //updateFactory
  //       .addCase(updateFactory.pending, state => {
  //         state.loading = 'loading'
  //         state.error = initialState.error
  //       })
  //       .addCase(updateFactory.fulfilled, (state, action) => {
  //         state.loading = 'succeeded'
  //         const payload = action.payload
  //         if (payload?.isSuccess) {
  //           const updateData = state.data.map(obj => (obj.id === action.payload.data.id ? action.payload.data : obj))
  //           state.data = updateData
  //         } else if (!payload?.IsSuccess) {
  //           state.error = payload?.Data?.ErrorMessage
  //         }
  //       })
  //       .addCase(updateFactory.rejected, (state, action) => {
  //         state.loading = 'failed'
  //         state.error = action.error.message as string
  //       })

  //       //deleteFactory
  //       .addCase(deleteFactory.pending, state => {
  //         state.loading = 'loading'
  //         state.error = initialState.error
  //       })
  //       .addCase(deleteFactory.fulfilled, (state, action) => {
  //         state.loading = 'succeeded'
  //         const payload = action.payload

  //         if (payload?.isSuccess) {
  //           const update = current(state.data).filter(obj => obj.id !== payload.data.id)
  //           state.data = update
  //         } else if (!payload?.IsSuccess) {
  //           state.error = payload?.Data?.ErrorMessage
  //         }
  //       })
  //       .addCase(deleteFactory.rejected, (state, action) => {
  //         state.loading = 'failed'
  //         state.error = action.error.message as string
  //       })
  //   },
})

const {reducer} = dashboard
export default reducer
